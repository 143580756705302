.root {
  display: flex;
  flex-direction: column;
  position: relative;
  /* background: #c4d4d2; */
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin: 0 0 40px;
}
.noResultsInfo{
  background: #C4D4D2 0% 0% no-repeat padding-box;
}
.searchResultSummary {

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
  font: normal normal normal 15px/24px Roc Grotesk;
  letter-spacing: 0px;
  color: #8D918D;
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
  & ul{
    max-width: 280px;
  }
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  padding: 8px 8px 8px 0;
}
