@import '../../../styles/customMediaQueries.css';

.sortBy {
  margin-right: 9px;
}

.sortByDesktop {
  margin-left: auto;
}

.sortByMenuLabel {
  display: inline-block;
  width: auto;
  height: 43px;
  min-height: 43px;

  padding: 10px 30px;
  margin: 0 9px 0 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font: normal normal normal 15px/24px Roc Grotesk;
  letter-spacing: 0px;
  color: #8D918D;

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  border: 1px solid #3A3D3A;
  border-radius: 28px;
  background-color: var(--colorWhite);

  &:focus,
  &:hover {
    outline: none;
    box-shadow: var(--boxShadowFilterButton);
    text-decoration: none;
    border-color: var(--colorGrey300);
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }
  @media (max-width: 767px) {
    height: 36px;
    min-height: 36px;
    padding: 7px 20px;
  }
}
