@import '../../../styles/customMediaQueries.css';

.root {
  display: inline-block;

  &:last-of-type {
    padding-right: 0;
    padding-bottom: 20px;
  }
}

.menuLabel,
.menuLabelSelected {
  display: inline-block;
  width: auto;
  height: 35px;
  min-height: 35px;

  padding: 0 18px;
  margin: 0 9px 0 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.2px;

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    /* box-shadow: var(--boxShadowFilterButton); */
    background: unset;
    text-decoration: none;
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    height: auto;
    min-height: 0;
    padding: var(--marketplaceButtonSmallDesktopPadding);
    margin: 0;

    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 20px;
  }
}

.menuLabel {
  /* Button borders */
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  border: none;
  /* Button colors: secondary */
  font: normal normal 900 44px/50px Roc Grotesk;
  letter-spacing: 0px;
  color: #3A3D3A;

  /* &:hover,
  &:focus {
    border-color: var(--colorGrey300);
  } */
  &:disabled {
    border-color: var(--colorGrey100);
  }

  @media (max-width: 767px) {
    font-size: 36px;
    line-height: 40px;
  }

  @media (--viewportMedium) {

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
}

.menuLabelSelected {
  /* Button borders */
  border-radius: var(--borderRadiusMedium);
  border: none;
  /* Button colors: secondary */
  font: normal normal 700 47px/50px Roc Grotesk;
  letter-spacing: 0px;
  color: #3A3D3A;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
  }

  /* &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  } */
  &:disabled {
    background-color: var(--colorGrey100);
    color: var(--colorWhite);
  }
}

.menuContent {
  margin-top: 7px;
  padding-top: 13px;
  border-radius: var(--borderRadiusMedium);
  max-width: 325px;
  width: 100%;
  min-width: 200px !important;
  left: 20% !important;
  right: 0;

  @media (max-width: 767px) {
    left: 5% !important;
    max-width: 280px;
  }

  @media(max-width:560px) {
    max-width: 200px;
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background: #BF5C3A;
}

.menuItem {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  width: 100%;
  font: normal normal normal 20px/30px Roc Grotesk;
  letter-spacing: 0px;
  color: #3A3D3A;

  &:focus,
  &:hover {
    color: var(--colorBlack);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
}

.clearMenuItem {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--colorGrey700);
    transition: width var(--transitionStyleButton);
  }
}

.menuBar {
  display: flex;
  align-items: center;
  gap: 20px;

  & svg {
    width: 35px;

    @media (max-width: 767px) {
      width: 27px;
      height: 40px;
    }
  }
}